<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Evaluación de requisitos</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(guardarEvaluacion)">
                            <b-row>
                                <b-col md="4">
                                    <validation-provider name="fecha de evaluación:" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Fecha de evaluación:" class="mb-2">
                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="datosEvaluaconRequisitos.fecha"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- <b-col v-if="datosEvaluaconRequisitos.idEvaluacion!=''" md="4">
                                    <validation-provider name="motivo revisión" :rules="{  }" v-slot="validationContext">
                                        <b-form-group label="Motivo revisión:" class="mb-2">
                                            <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="datosEvaluaconRequisitos.motivoRevision" placeholder="Ingrese motivo revisión" rows="4" max-rows="6"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col> -->
                            </b-row>

                            <!-- <b-col class="mt-3" md="12">
                                <span class="h6 text-muted">POR FAVOR, AGREGUE LOS RIESGOS QUE DESEA EVALUAR</span>
                                <CButton size="sm" class="float-right" color="dark" @click="modalAgregarRiesgos = true">
                                    <i class="fas fa-plus fa-sm"></i> Agregar riesgos
                                </CButton>
                            </b-col> -->
                            <b-col class="mt-3">
                                <hr>
                            </b-col>
                            <b-col md="12">
                                <div class="accordion mt-1" visible role="tablist" v-for="(requisitos, k) in datosEvaluaconRequisitos.listaDetalleRequisitos" :key="k">
                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                            <div block v-b-toggle="'accordion-'+requisitos.idRequisito" class="bg-accordion">
                                                <span class=" h6"> {{requisitos.nombre}}</span>
                                                <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                            </div>
                                        </b-card-header>
                                        <b-collapse :id="'accordion-'+requisitos.idRequisito" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <b-row>
                                                    <b-col md="12">
                                                        <validation-provider :name="'metodología de evaluación de '+requisitos.nombre" :rules="{   }" v-slot="validationContext">
                                                            <b-form-group label="Metodología de evaluación:" class="mb-2">
                                                                <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="requisitos.metodologiaEvaluacion" placeholder="Ingrese la metodología" rows="4" max-rows="6"></b-form-textarea>
                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col md="12">
                                                        <b-form-group label="Evaluación cumplimento legal:" class="mb-2">
                                                            <b-form-radio-group small plain v-model.lazy="requisitos.evaluacionCumplimiento" :options="radioEvaluacion"></b-form-radio-group>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="12">
                                                        <validation-provider :name="'observaciones de '+requisitos.nombre" :rules="{  }" v-slot="validationContext">
                                                            <b-form-group label="Observaciones:" class="mb-2">
                                                                <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="requisitos.observaciones" placeholder="Ingrese las observaciones" rows="4" max-rows="6"></b-form-textarea>
                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </b-col>
                            <b-col class="my-2 text-center" md="12">
                                <!-- <b-button class="mr-2" :to="{name: 'Selección riesgos'}" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button> -->
                                <b-button v-if="$route.params.id!=null" :to="{name: 'Selección requisitos', params: {id: $route.params.id}}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id==null" :to="{name: 'Selección requisitos'}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button :disabled="disabled"  v-if="checkPermissions('004-14001-REA','c')==1 && datosEvaluaconRequisitos.listaDetalleRequisitos.length>0 && !$route.params.id" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                                <b-button :disabled="disabled"   v-if="checkPermissions('004-14001-REA','u')==1 && datosEvaluaconRequisitos.listaDetalleRequisitos.length>0 && $route.params.id" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            modalAgregarRiesgos: false,
            arrayRequisitos: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosEvaluaconRequisitos: {
                idEvaluacion: '',
                fecha: moment().format('YYYY-MM-DD'),
                listaDetalleRequisitos: [],
            },
            radioEvaluacion: [{
                value: 1,
                text: "NO"
            }, {
                value: 2,
                text: "SI"
            }],

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        agregarRequisitos() {
            let me = this;
            let arrayRequisitos = me.$route.params.arrayRequisitos;
            for (const i in arrayRequisitos) {
                me.datosEvaluaconRequisitos.listaDetalleRequisitos.push({
                    idEvaluacionDetalleRequisito: '',
                    idEvaluacionRequisito: '',
                    idRequisito: arrayRequisitos[i].idRequisito,
                    nombre: arrayRequisitos[i].nombre,
                    metodologiaEvaluacion: arrayRequisitos[i].metodologiaEvaluacion,
                    evaluacionCumplimiento: arrayRequisitos[i].evaluacionCumplimiento,
                    observaciones: '',
                });
            }
        },
        consultarEvaluacionRequisito() {
            let me = this;
            let arrayRequisitos = me.$route.params.arrayRequisitos;
            me.datosEvaluaconRequisitos.listaDetalleRequisitos = arrayRequisitos;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-requisito", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosEvaluaconRequisitos.idEvaluacion = response.data[0].idEvaluacion;
                    me.datosEvaluaconRequisitos.fecha = response.data[0].fecha;

                    for (const j in arrayRequisitos) {
                        for (const i in response.data) {
                            if (arrayRequisitos[j].idRequisito == response.data[i].idRequisito) {
                                me.datosEvaluaconRequisitos.listaDetalleRequisitos.splice(j, 1, {
                                    idEvaluacionDetalleRequisito: response.data[i].idEvaluacionDetalleRequisito,
                                    idEvaluacion: response.data[i].idEvaluacion,
                                    idRequisito: response.data[i].idRequisito,
                                    nombre: response.data[i].nombre,
                                    metodologiaEvaluacion: response.data[i].metodologiaEvaluacion,
                                    evaluacionCumplimiento: response.data[i].evaluacionCumplimiento,
                                    observaciones: response.data[i].observaciones,
                                })
                            }
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        guardarEvaluacion() {
            let me = this;

            /* me.$router.push({
                name: 'Generar gráfico evaluación',
                params: {
                    datosArray: me.datosEvaluaconRequisitos.listaDetalleRequisitos,
                }
            }); */
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-requisito-sgma", {
                        params: {
                            datosEvaluaconRequisitos: me.datosEvaluaconRequisitos,
                            idCliente: me.datosSession.idCliente
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.$router.push({
                        name: 'Requisitos medioambientales 14001',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            if (this.$route.params.arrayRequisitos != null && this.$route.params.id == null ) {
                this.agregarRequisitos();
            } else if (this.$route.params.arrayRequisitos != null && this.$route.params.id != null) {
                this.consultarEvaluacionRequisito();
            }
        }
    }

}
</script>
